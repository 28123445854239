$primary: rgb(233, 66, 0);
$text: rgb(29, 29, 29);
$background: rgb(255, 255, 255);
$highlight: rgb(240, 240, 240);
$light: rgb(233, 66, 0);//rgb(240, 240, 240);

body{
    background-color: $background;
    overflow-y: scroll;
    @media screen and (max-width: 30em){
        overflow: auto;
    }
}
*{
    font-family: 'Poppins';
    font-weight: 300;
    color: $text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::selection {
    background-color: $primary;
    color: white;
}

h1,h2,h3,h4,h5,h6{
    color: $primary;
    font-family: 'Playfair Display';
}
main{
    position: absolute;
}
article{
    min-height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    overflow: visible;
    margin-bottom: 50px;
    @media screen and (max-width: 45em){
        display: flex;
        flex-direction: column;
    }
}
a{
    transition: color .4s ease-in-out !important;
    &:active,&:focus{
        outline: none !important;
    }
}
a:hover{
    color: $primary !important;
    opacity: 1 !important;
}
.nav{
    position: fixed;
    background-color: transparent !important;
    z-index: 101;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 680px){
        justify-content: center;
    }
}
.lang_selectors{
    a{
        letter-spacing: 4px;
        margin: 0 5px;
        font-size: 15px;
        font-weight: 600;
        &.active{
            color: $highlight;
        }
    }
}
.footer{
    position: fixed;
    bottom: 0;
    width: auto;
    a{
        letter-spacing: 4px;
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 600;
    //font-family: 'Cinzel', serif;
    }
    @media screen and (max-width: 45em){
        width: 100%;
        z-index: 100;
        background-color: $background !important;
        display: flex;
        justify-content: center;
        padding: 1rem;
        text-align: center;
    }
}
.sidebar{
    position: relative;
    top: 20%;
    //transform: translateY(-50%);
    @media screen and (max-width: 30em){
        padding-left: 25px;
        padding-right: 25px;
        .content_title{
            margin-top: 1rem;
        }
    }
    @media screen and (max-width: 45em){
        top: auto;
        position: static;
        transform: none;
        background-color: $background;
        z-index: 100;
        width: 100%;
    }
}

::-webkit-scrollbar {
    width: 0px; 
    background: transparent; 
}

.page_content{
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;
    top: -4rem;
    position: relative;
    .page_content_body{
        padding-top: 14vh;
        padding-bottom: 4rem;
        *{
            line-height: 1.9rem;
            font-size: 1.1rem;
            font-weight: 300;
        }
        figure{
            margin: 2rem 0;
            @media screen and (max-width: 30em){
                margin: 2rem -25px;
            }
        }
        @media screen and (max-width: 30em){
            height: auto;
            position: static;
            overflow: auto;
            padding-left: 25px;
            padding-right: 25px;
        }
        @media screen and (max-width: 45em){
            padding-top: 50px;
            padding-bottom: 50vh;
        }
    }
    @media screen and (max-width: 45em){
        padding: 15px 0;
        margin: 0;
        width: 100%;
    }
}

.content_title{
    font-size: 3rem;
    @media screen and (max-width: 80em){
        font-size: 3rem;
    }
    &:after{
        content: "";
        display: block;
        width: 100px;
        height: 2px;
        position: relative;
        bottom: -20px;
        background-color: $highlight;
    }
}


.hiddenMobile{
    @media screen and (max-width: 680px){
       display: none;
    }
}
.blurred{
  filter: blur(5px);
}
.transparent{
  opacity: 0;
}
#intro{
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    //z-index: 1000;
    //background-color: rgb(22, 22, 22);
}
#intro_logo{
    width: 43vw;
    height: auto;
    position: absolute;
    top: 50%;    
    left: 50%;
    transform: translate(-50%,-50%);
    filter: blur(10px);   
}
.hiddenIntro{
  opacity: 0;
  display: none;
}
.menu{
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 101;
    padding: 40px;
    background-color: $background;
    .menu_content{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        a.menulink{
            //font-family: 'Cinzel',serif;
            color: $text;
            font-size: 1.4em;
            font-weight: 400;
            margin: 5px 0;
        }
    }
    .menu_logo{
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 101;
        .logo{
            width: 60vw;
            height: auto;
            @media screen and (max-width: 680px) {
                max-width: 100vw;
                width: 100vw;
            }
        }
    }
}
.hamburger {
    outline: none;
}